import React, { useState, useEffect } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Text } from "@chakra-ui/react";

const NotificationPopup = ({ isOpen, message }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  console.log({isOpen,message})


  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handlePayNow = () => {
    window.location.href = "/myProfile";
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isModalOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Notification</ModalHeader>
        <ModalBody>
          <Text>{message}</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleClose}>
            Close
          </Button>
          {/* <Button colorScheme="green" onClick={handlePayNow}>
            Pay Now
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NotificationPopup;
